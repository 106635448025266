<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16"
  >
    <!----------------------------------------POLICY NUMBERS----------------------------------------->
    <base-material-card
      color="primary"
      icon="mdi-chart-pie"
      title="Annual Sales"
      class="px-4 py-3"
    >
      <v-row class="d-flex justify-center mt-0">
        <v-col
          cols="9"
        >
          <btn
            class="red"
            @click="createaccountforuser"
          >
            Create Account for user
          </btn>
          <v-row class="d-flex justify-center">
            <v-col
              cols="6"
            >
              <v-select
                v-model="year"
                outlined
                :items="years"
                label="Select Year"
                @change="showSelectedData(year)"
              ></v-select>
            </v-col>
            <!-- <v-col
              cols="6"
            >
              <v-select
                v-model="year"
                outlined
                :items="years"
                label="Select Year"
                @change="showSelectedData(agent, year)"
              ></v-select>
            </v-col> -->
          </v-row>
          <v-card
            class="pa-4 mt-2"
            elevation="12"
          >
            <v-card-title class="d-flex justify-center">
              Sales for {{ year }} : ${{ sum }} Thousand
            </v-card-title>
            <div id="chart">
              <v-row class="d-inline-flex justify-center ml-4">
                <v-col>
                  <div>{{ year }}</div>
                </v-col>
              </v-row>
              <apexchart
                ref="totalSalesByYear"
                type="bar"
                height="350"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </base-material-card>
    <base-material-card
      color="primary"
      icon="mdi-file-table"
      title="Annual Sales"
      class="px-4 py-3"
    >
      <v-row class="d-flex justify-center">
        <v-col cols="12">
          <v-data-table
            dense
            :headers="headers"
            :items="salesData"
            item-key="name"
            class="elevation-1"
          ></v-data-table>
        </v-col>
      </v-row>
    </base-material-card>
    <!-----------------------------------POLICY VISUALS------------------------------->
  </v-container>
</template>

<script>

  export default {

    data: () => ({
      headers: [{
                  text: 'Year',
                  value: 'year',
                  align: 'start',
                  sortable: false,
                },
                { text: 'Jan', value: 'jan' },
                { text: 'Feb', value: 'feb' },
                { text: 'Mar', value: 'mar' },
                { text: 'Apr', value: 'apr' },
                { text: 'May', value: 'may' },
                { text: 'Jun', value: 'jun' },
                { text: 'Jul', value: 'jul' },
                { text: 'Aug', value: 'aug' },
                { text: 'Sep', value: 'sep' },
                { text: 'Oct', value: 'oct' },
                { text: 'Nov', value: 'nov' },
                { text: 'Dec', value: 'dec' },
                { text: 'Total', value: 'total' }
      ],
      salesData: [{ year: '2022', jan: '20k', feb: '40k', mar: '40k', apr: '100k', may: '30k', jun: '30k', jul: '50k', aug: '45k', sep: '35k', oct: '40k', nov: '60k', dec: '25k', total: '505k' }],
      list: '',
      selectedAgent: '',
      sum: '',
      agents: [],
      years: ['2021', '2022', '2023', '2024'],
      year: '',
      series: [],
      chartOptions: {
        chart: {
          height: 350,
          type: 'bar',
          events: {
            click: function (chart, w, e) {
              // console.log(chart, w, e)
            }
          }
        },
        // colors: colors,
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return '$' + val + ' K'
            }
          }
        },
        xaxis: {
          categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          labels: {
            style: {
              fontSize: '12px'
            }
          }
        },
        yaxis: {
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            formatter: function (val) {
              return val + 'K'
            }
          }

        },
      }
    }),
    computed: {
      getCurrentUserProfileId () {
        let key = this.$store.getters.getCurrentUserProfileId
        console.log('our user key is ', key)
        return key
      },
      agentList () {
        let list = this.agents
        list.forEach(entry => {
          let wholename = ' '
          wholename = entry.firstName + ' ' + entry.lastName
          Object.assign(entry, { wholename: wholename })
        })
        return list
      }
    },

    methods: {
      createaccountforuser () {
        let email = 'autouser@autouser.com'
        let pass = '12564618142'
        this.$store.dispatch('createAutomaticAccountandSignIn', { email: email, phonenumber: pass })
      },
      showSelectedData (year) {
        let totalSales = this.$store.getters.totalSales
        console.log(totalSales)
        let totalYearlySales = totalSales.filter((item) => {
          return item.year === year
        })
        let newSeries = totalYearlySales[0].data
        console.log(totalYearlySales)
        let sum = 0
        for (let i = 0; i < newSeries.length; i++) {
          sum += newSeries[i]
        }
        this.$refs.totalSalesByYear.updateSeries([{ data: newSeries }])
        this.sum = sum
      }
    }
  }
</script>

<style lang="scss" scoped>
.policy-numbers {
  font-size: 36px;
}
</style>
